<template>
  <v-card
    hover
    height="100%"
    :ripple="false"
    @click="openGroup(group.groupid)"
  >
 
    <v-card-title>
      <p>
        <span class="font-weight-light">{{group.name}}</span><br />
      </p>
    </v-card-title>
 
    <v-spacer></v-spacer>

    <v-card-actions>

      <v-spacer></v-spacer>

      <v-menu
        v-if="allowEdit"
        offset-y
      >

        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"              
            style="cursor: pointer;"
          >mdi-dots-vertical</v-icon>&nbsp;
        </template>

        <v-list>

          <v-list-item 
            @click="renameGroup(group.groupid)" 
            style="cursor: pointer;"
          >
            <v-list-item-title>Rename</v-list-item-title>
          </v-list-item>

          <!--<v-divider></v-divider>

          <v-list-item 
            @click="deleteCourse(course.courseid)" 
            style="cursor: pointer;"
          >
            <v-list-item-title><span style="color: #aa4444;">Delete</span></v-list-item-title>
          </v-list-item>-->

        </v-list>

      </v-menu>  
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'GroupCard',

    data: () => ({
      allowEdit: false,   
    }),

    props: {    

      group: {
        type: Object,
        default: null,
        required: true
      },

    },

    created() {

      this.allowEdit = false;

      switch (this.group.grouptypeid) {

        case 1: // Home
          for (const role of this.group.roles) {
            if (role.grouproleid == 1) { // parent?
              this.allowEdit = true;
            }
          }
          break;

        case 2: // Class
          for (const role of this.group.roles) {
            if (role.grouproleid == 2) { // teacher?
              this.allowEdit = true;
            }
          }
          break;

      }

    },

    methods: {

      openGroup(groupId) { 
        if (this.allowEdit) {
          this.$router.push('group/' + groupId);
        }
        
      },      

      renameGroup(groupId) {
        let name = prompt("New group name", this.group.name);
        if (!(name == null || name == "")) {
          this.$emit('renameGroup', groupId, name);
        }
      },

    }

  }
</script>
